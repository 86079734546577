<template>
    <section class="informacion-preguntas-frecuentes control-old overflow-auto custom-scroll">
        <div v-for="(pre,index) in preguntas" :key="index" class="row mx-0 mb-3">
            <p class="col-12 f-20 f-500 mb-3">{{ pre.titulo }}</p>
            <div v-if="pre.preguntas.length" class="col-12 accordion-informacion">
                <el-collapse accordion>
                    <el-collapse-item v-for="(hija,indexH) in  pre.preguntas" :key="indexH" :title="hija.titulo" :name="indexH">
                        <div class="f-15 lh-18" style="white-space:pre-line;">{{ hija.texto }}</div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </section>
</template>

<script>
import Faqs from '@/services/faqs/faqs'
export default {
    data(){
        return {
            preguntas: []
        }
    },
    mounted(){
        this.getPreguntas()
    },
    methods: {
        async getPreguntas(){
            try {
                let tipo = 1 
                const { data } = await Faqs.listar({tipo})
                this.preguntas = data.data
            } catch (error) {
                this.errorCatch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.informacion-preguntas-frecuentes{
    height: 90vh;
}
</style>
